.ant-table td {
  background: transparent;
}

.ant-btn-primary {
  background-color: #ed7342;
}

.ant-pagination-item-active a {
  color: #ed7342 !important;
}

.ant-pagination-item-active {
  border-color: #ed7342 !important;
}

.ant-transfer > * {
  font-family: Inter, sans-serif;
}

.ant-checkbox-checked:after {
  border: 2px solid #ed7342;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ed7342;
  border-color: #ed7342;
}

.ant-checkbox-inner:after {
  background-color: #ed7342 !important;
}

.ant-pagination-options {
  display: none !important;
}
